import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Aboutus from '../assets/img/Aboutus.png';

const About = () => {
  const containerStyle = {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '4rem 2.5rem 4rem 10rem',
    boxSizing: 'border-box',
  };

  const imageStyle = {
    position: 'absolute',
    top: 30,
    right: 90,
    bottom: 0,
    width: '67%',
    backgroundImage: `url(${Aboutus})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animation: 'rotateImage 20s linear infinite',
    paddingRight: '45%', // Adjust padding to move the image more towards the left
  };

  const keyframes = `
    @keyframes rotateImage {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(8deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-8deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  `;

  return (
    <div>
      <style>{keyframes}</style>
      <div style={containerStyle}>
        <div style={imageStyle}></div>
        <div className="text-left relative z-10">
          <h1 className="font-clash text-5xl font-semibold mb-5">
            <span className="text-white">Step into</span> <br />
            <span className="bg-gradient-to-r from-teal-400 to-purple-500 bg-clip-text text-transparent">
              Tan Theta
            </span>
          </h1>
          <p className="text-white text-base leading-relaxed mb-5 max-w-lg">
            Tan Theta is a cutting-edge protocol designed for the Fuel Blockchain, offering unparalleled capabilities for developing decentralized applications on Fuel. 
            Our protocol empowers developers and businesses to create innovative and highly scalable products seamlessly with ease and efficiency.
          </p>
          <div className="text-left">
            <a href="https://tantheta.io" target="_blank" rel="noopener noreferrer">
              <button className="bg-gradient-to-r from-teal-400 to-purple-500 border-none font-clash font-semibold rounded-lg text-white transition-colors duration-300 px-6 py-3">
                Know More
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
