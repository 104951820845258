import React from 'react';
import { motion } from 'framer-motion';
import teamMember1 from '../assets/img/Pratik.png'; 
import teamMember2 from '../assets/img/Swaraj.png'; 
import teamMember3 from '../assets/img/Vaibhav.png'; 
import teamMember4 from '../assets/img/Palak.png'; 
import teamMember5 from '../assets/img/Sudhanshu.png'; 
import teamMember6 from '../assets/img/Akash.png'; 
import teamMember7 from '../assets/img/Ankit.png'; 
import teamMember8 from '../assets/img/Sakshi.png'; 
import teamMember9 from '../assets/img/Sweta.png'; 
import teamMember10 from '../assets/img/Ritvik.png'; 
import teamMember11 from '../assets/img/Sahil.png'; 
import './Team.css'; // CSS for styling

const Team = () => {
  
  const teamMembers = [
    { name: 'Swaraj Phadtare', Designation: 'Co - Founder and CEO', image: teamMember2},
    { name: 'Pratik Oswal', Designation: 'Co - Founder and CMO', image: teamMember1 },
    { name: 'Vaibhav Nagpal', Designation: 'Head of Engineering', image: teamMember3 },
    { name: 'Palak Motwani', Designation: 'BD & Strategy', image: teamMember4 },
    { name: 'Sudhanshu Dubey', Designation: 'Lead Frontend Developer', image: teamMember5 },
    { name: 'Akash Shinde', Designation: 'Backend Dev', image: teamMember6 },
    { name: 'Ankit Saini', Designation: 'Blockchain Lead', image: teamMember7 },
    { name: 'Sakshi Thombre', Designation: 'Frontend Dev', image: teamMember8 },
    { name: 'Sweta Singh', Designation: 'Frontend Dev', image: teamMember9 },
    { name: 'Ritvik Srivastava', Designation: 'Backend Dev', image: teamMember10 },
    // { name: 'Sahil Potdukhe', Designation: 'Senior Frontend Dev', image: teamMember11 },
  
   
  ];

  
  const renderTeamRows = () => {
    const rows = [];
    for (let i = 0; i < teamMembers.length; i += 5) {
      const rowItems = teamMembers.slice(i, i + 5);
      rows.push(
        <div key={i} className="team-cards-row">
          {rowItems.map((member, index) => (
            <motion.div 
              key={index} 
              className="team-card"
              whileHover={{ scale: 1.05, rotate: 2, translateY: -10, translateZ: 20 }}
            >
              <motion.img 
                src={member.image} 
                alt={member.name} 
                className="team-member-image" 
                whileHover={{ translateZ: 50 }} 
              />
              <div className="member-info">
                <h3>{member.name}</h3>
                <p>{member.Designation}</p>
              </div>
            </motion.div>
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <div className="team-page">
      <div className="team-header">
        <h2 className="gradient-text">Our Team</h2>
        <p>Meet the talented individuals driving Tan Theta forward.</p>
      </div>
      {renderTeamRows()}
    </div>
  );
};

export default Team;


