import React from 'react';
import './UseCase.css'; 

import img1 from '../assets/img/scroll1.png';
import img2 from '../assets/img/scroll2.png';
import img3 from '../assets/img/scroll3.png';
import img4 from '../assets/img/scroll4.png';
import img5 from '../assets/img/scroll5.png';

const useCases = [
  {
    title: "Create Prediction Market",
    description: "Effortlessly build and deploy decentralized prediction markets with Tanθ. Use our order matching and event creation features to offer a reliable, engaging platform with high liquidity.",
    img: img1
  },
  {
    title: "Develop Sports Books",
    description: "Develop decentralized sports books with ease. Our protocol's event creation and oracle integration ensure accurate, real-time data, enhancing the user experience.",
    img: img2
  },
  {
    title: "Launch Spot DEX",
    description: "Create a decentralized spot exchange with our predicates-based order matching and aggregated liquidity features. Ensure deep liquidity and low latency.",
    img: img3
  },
  {
    title: "Build a Market Place",
    description: "Utilize our protocol to develop a decentralized marketplace. Leverage our event creation and order-matching capabilities to create a dynamic, efficient trading environment.",
    img: img4
  },
  {
    title: "Build a Derivatives DEX",
    description: "Build a derivatives exchange with our protocol's order matching and liquidity aggregation. Provide a robust, efficient platform for trading complex instruments.",
    img: img5
  }
];

const extendedUseCases = [...useCases, ...useCases,...useCases,...useCases,...useCases,...useCases,...useCases]; 

const UseCase = () => {
  return (
    <section id="usecase">
      <div className="usecase-container">
        <h1>Use Cases</h1>
        <p>Discover practical applications and real-world scenarios where our solutions make a difference.</p>
        <div className="usecase-content">
          <div className="usecase-cards">
            {extendedUseCases.map((useCase, index) => (
              <div key={index} className="scroll-card">
                <h3>{useCase.title}</h3>
                <div className="card-image">
                  <img src={useCase.img} alt={useCase.title} />
                  <div className="card-content">
                    <p>{useCase.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCase;