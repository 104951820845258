"use client";
import React, { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import "./Home.css";

const subtext = `Revolutionize your business with our robust protocol on Fuel Blockchain for Prediction Markets, Spot Exchanges, Derivatives Exchange.`;

const TextGenerateEffect = ({ words, className }) => {
  const [scope, animate] = useAnimate();
  const wordsArray = words.split(" ");

  useEffect(() => {
    animate(
      "span",
      { opacity: 1 },
      { duration: 2, delay: stagger(0.2) }
    );
  }, [scope.current]);

  const renderWords = () => {
    return (
      <motion.div ref={scope} style={{ textAlign: 'center' }}>
        {wordsArray.map((word, idx) => (
          <motion.span
            key={word + idx}
            className="word"
          >
            {word}{" "}
          </motion.span>
        ))}
      </motion.div>
    );
  };

  return (
    <div className={`font-semibold ${className}`} style={{ textAlign: 'center' }}>
      <div className="text-container">
        <div className="text">
          {renderWords()}
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <section id="home" className="section">
      <div className="home">
        <div className="Frame">
          <div className="overlay">
            <div className="content">
              <h1 className="heading">
                Trade the Unconventional with <br />
                <span className="gradient-text">Tan Theta</span>
              </h1>
              <TextGenerateEffect words={subtext} className="subtext" />
              <div className="buttons">
                <a href="/investors" className="btn btn-primary btn-secondary custom-btn">Get Started</a>
                <a href="https://docs.tantheta.io" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default Home;
