import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Investors from './components/Investors';
import About from './components/About';
import UseCase from './components/UseCase';
import OurMission from './components/OurMission';
import Projects from './components/Projects';
import Features from './components/Features';
import Team from './components/Team';
import ContactForm from './components/ContactForm';
import './App.css';


function App() {
  return (
    <>
      <div className="App">
     
        <Navbar />
        <Home />
        <Investors />
        <About />
        <Features />
        <UseCase />
        <OurMission />
        <Projects />
        <Team />
        <ContactForm />
        
      </div>
    </>
  );
}

export default App;
