import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Projects.css';
import griffyImage from '../assets/img/griffy copy.png';
import Coin_Switch from '../assets/img/coin_switch.png';
import trade from '../assets/img/tradexM.png';
import logo1 from '../assets/img/griffy.png'; // Import logos for each project
import logo2 from '../assets/img/CoinSwitch.png';
import logo3 from '../assets/img/trade.png';

const projects = [
    {
        image: griffyImage,
        logo: logo1,
        name: 'Griffy',
        // description: 'This is a brief description of Project One.',
        link: 'https://griffy.app',
    },
    {
        image: trade,
        logo: logo3,
        name: 'TradeX',
        // description: 'This is a brief description of Project One.',
        link: 'https://www.tradexapp.co',
    },
    {
        image: Coin_Switch,
        logo: logo2,
        name: 'CoinSwitch',
        // description: 'This is a brief description of Project One.',
        link: 'https://coinswitch.co',
    },
];

const ProjectCard = ({ image, logo, name, description, link }) => {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="group block transform transition-transform hover:-translate-y-2">
            <div className="shadow-lg rounded-lg w-84 overflow-hidden h-94">
                <img src={image} alt={name} className="w-full h-64 object-scale-down bg-white" />
                <div className="p-4">
                    <div className="flex items-center">
                        <img src={logo} alt="Logo" className="w-12 h-12 rounded-full mr-2" /> {/* Updated size to 40px */}
                        <h3 className="text-lg font-semibold">{name}</h3>
                    </div>
                    <p className="mt-2 text-gray-fff">{description}</p>
                </div>
            </div>
        </a>
    );
};

const Projects = () => {
    return (
        <section id="projects" className="section">
            <div className="py-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold text-center" style={{ fontFamily: 'Clash Display', background: 'linear-gradient(45deg, #34f0e2, #bc5df5)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        Projects Building on Tan Theta
                    </h1>
                    <p className="mt-4 text-center text-lg text-gray-fff">
                        Unveil the pioneering projects and achievements made possible through TanTheta
                    </p>
                    <div className="mt-10 py-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        {projects.map((project, index) => (
                            <ProjectCard key={index} image={project.image} logo={project.logo} name={project.name}  link={project.link} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Projects;


// import { AnimatedPinDemo } from '../components/elements/ThreeDpin';
// description={project.description}
// const Projects = () => {
//   return (
//     <div style={{ backgroundSize: 'cover', height: '100vh', color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
//       <div style={{ transform: 'scale(1.75)' }}> {/* Adjust scale value as needed */}
//         <AnimatedPinDemo />
//       </div>
//       <div style={{ transform: 'scale(1.75)' }}> {/* Adjust scale value as needed */}
//         <AnimatedPinDemo />
//       </div>
//       <div style={{ transform: 'scale(1.75)' }}> {/* Adjust scale value as needed */}
//         <AnimatedPinDemo />
//       </div>
//     </div>
//   );
// }



// const Projects = () => {
//   return (
//     <div className="projects">
//       <div className="content">
//         <h1 className="heading">Projects Building on Tan Theta</h1>
//         <p className="subheading">Unveil the pioneering projects and achievements made possible through TanTheta.</p>
//         <div className="cards-container">
//           <div className="card">
//             <img src={griffyImage} alt="Griffy Project" className="card-image" />
//             <div className="card-content">
//               <a href="https://griffy.app" target="_blank" rel="noopener noreferrer" className="card-title">Griffy</a>
//             </div>
//           </div>
//           <div className="card">
//             <img src={griffyImage} alt="Griffy Project" className="card-image" />
//             <div className="card-content">
//               <a href="https://griffy.app" target="_blank" rel="noopener noreferrer" className="card-title">Griffy</a>
//             </div>
//           </div>
//           <div className="card">
//             <img src={griffyImage} alt="Griffy Project" className="card-image" />
//             <div className="card-content">
//               <a href="https://griffy.app" target="_blank" rel="noopener noreferrer" className="card-title">Griffy</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Projects;
