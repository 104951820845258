import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import './Navbar.css';

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const navbarRef = useRef(null);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <header className={`head ${!visible ? 'hidden' : ''}`} ref={navbarRef}>
      <nav className="nav container">
        <div className="nav__data">
          <NavLink to="/" className="nav__logo" onClick={() => scrollToSection('home')}>
            <img src={logo} alt="Logo" />
          </NavLink>
        </div>
        <ul className="nav__list">
          <li><NavLink exact to="/" className="nav__link" onClick={() => scrollToSection('home')}>Home</NavLink></li>
          <li><NavLink exact to="/features" className="nav__link" onClick={() => scrollToSection('features')}>Features</NavLink></li>
          <li><NavLink exact to="/usecase" className="nav__link" onClick={() => scrollToSection('usecase')}>Use Cases</NavLink></li>
          <li><NavLink exact to="/about" className="nav__link" onClick={() => scrollToSection('about')}>About Us</NavLink></li>
          <li><NavLink exact to="/projects" className="nav__link" onClick={() => scrollToSection('projects')}>Projects</NavLink></li>
          <li><a href="https://docs.tantheta.io/" target="" rel="noopener noreferrer" className="nav__button">Documentation</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
