import React from 'react';
import { FaEnvelope, FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contact-form-container">
      <div className="contact-info-section">
        <h3 className="gradient-text">Contact Us</h3>
        <p>We'd love to hear from you! Whether you have a question, need support, or want to collaborate, reach out to us via email or social media.</p>
        <div className="social-media">
          <p>Follow Us</p>
          <div className="icons">

            <a href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJZWPwsRnhHPQcjCrxsNKtsHXRDDmFHTcsqNtnPJJcgZWnvJFSWvLXHmkzDJJpfnhPGTklB"><FaEnvelope className="icon" /></a>
            <a href="https://twitter.com/TanThetaTT" target="_blank" rel="noopener noreferrer"><FaTwitter className="icon" /></a>
            <a href="https://t.me/cryptomathemagician" target="_blank" rel="noopener noreferrer"><FaTelegramPlane className="icon" /></a>
          </div>
        </div>
      </div>
      <div className="form-section">
        {/* <h2 className="gradient-text">Get in Touch</h2>
        <p>Let us know more about you!</p> */}
        <form>
          <div className="form-row">
            <input type="text" placeholder="First Name" className="form-input" />
            <input type="text" placeholder="Last Name" className="form-input" />
          </div>
          <div className="form-row">
            <input type="email" placeholder="Mail" className="form-input" />
            <input type="tel" placeholder="Phone" className="form-input" />
          </div>
          <div className="form-row">
            <textarea placeholder="Message" className="form-input message-input"></textarea>
          </div>
          <button type="submit" className="submit-button">SUBMIT</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
