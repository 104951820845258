// src/components/FeaturesPage.js

import React from 'react';
import FeaturesFrame from '../assets/img/FeaturesFrame.svg'; // Import the SVG file
import './Features.css'; // Custom styles for this component

const FeaturesPage = () => {
  return (
    <section id="features" className="section">
    <div className="features-page">
      <h1 className="features-heading">Features</h1>
      <p className="features-subheading">Discover the power and flexibility of our protocol.</p>
      <img src={FeaturesFrame} alt="Features Frame" className="features-image" />
    </div>
    </section>
  );
};

export default FeaturesPage;
