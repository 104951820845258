import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import './OurMission.css';

const missiontext = `At Tanθ, we drive innovation in the decentralized space by providing essential tools and infrastructure for developers and businesses to create groundbreaking decentralized applications. Our solutions address key challenges in prediction markets, enhance liquidity, and ensure transparency and reliability. We empower the decentralized community to build and scale applications with confidence, shaping a more efficient digital world.`;

const TextGenerateEffect = ({ words, className }) => {
  const controls = useAnimation();
  const wordsArray = words.split(" ");
  const intervalRef = useRef(null);

  useEffect(() => {
    const animateText = async () => {
      await controls.start({
        opacity: 1,
        transition: { duration: 5}
      });

      await controls.start({
        opacity: 1,
        transition: { duration: 25}
      });

      
      animateText();
    };

    animateText();

    intervalRef.current = setInterval(() => {
      animateText();
    }, 25000); // 15 seconds

    return () => clearInterval(intervalRef.current);
  }, [controls]);

  return (
    <div className={`font-bold ${className}`} style={{ textAlign: 'center' }}>
      <div className="text-container">
        <motion.div className="text">
          {wordsArray.map((word, idx) => (
            <motion.span
              key={word + idx}
              className="word"
              initial={{ opacity: 1 }}
              animate={controls}
            >
              {word}{" "}
            </motion.span>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

const OurMission = () => {
  return (
    <div className="our-mission">
      <div className="circle-container">
        <div className="content">
          <h1 className="heading">Our Mission</h1>
          <TextGenerateEffect words={missiontext} className="missiontext" />
        </div>
      </div>
    </div>
  );
}

export default OurMission;
