import React from 'react';
import investor1 from '../assets/img/investor1.png';
import investor2 from '../assets/img/investor2.png';
import investor3 from '../assets/img/investor3.png';
import investor4 from '../assets/img/investor6.png';
import investor5 from '../assets/img/investor5.png';
import investor13 from '../assets/img/investor13.png';
import investor6 from '../assets/img/investor4.png';
import investor7 from '../assets/img/investor7.png';
import investor8 from '../assets/img/investor8.png';
import investor9 from '../assets/img/investor9.png';
import investor10 from '../assets/img/investor10.png';
import investor11 from '../assets/img/investor11.png';
import investor12 from '../assets/img/investor12.png';
import investor14 from '../assets/img/investor14.png';
import investor15 from '../assets/img/investor15.png';

import logo1 from '../assets/img/logo1.png';
import logo2 from '../assets/img/logo2.png';
import logo3 from '../assets/img/logo3.png';
import logo4 from '../assets/img/logo4.png';
import logo5 from '../assets/img/logo5.png';

import './Investors.css';

const Investors = () => {
  const investors = [
    { img: investor1, name: 'Sandeep Nailwal', designation: 'Co-Founder Polygon' },
    { img: investor2, name: 'Yoann Turpin', designation: 'Co-Founder Wintermute' },
    { img: investor3, name: 'Zhen Yong', designation: 'Co-Founder Web3Auth' },
    { img: investor4, name: 'Aniket Jindal', designation: 'Co-Founder Biconomy' },
    { img: investor5, name: 'Arjun Kalsy', designation: 'COG, Fuel Network' },
    { img: investor13, name: 'Anuj Shankar', designation: 'Founder Luganodes' },
    { img: investor6, name: 'Eric Wang', designation: 'Head of BD, Analog' },
    { img: investor7, name: 'Harsh Rajat', designation: 'CEO, Push Protocol' },
    { img: investor8, name: 'Mariyan Boychev', designation: 'Co-Founder Cryowar' },
    { img: investor9, name: 'Ramani Ram', designation: 'CEO Router Protocol' },
    { img: investor10, name: 'Satyam Bhasin', designation: 'Ex-Director CitiBank' },
    { img: investor11, name: 'Vaibhav Tiwari', designation: 'Ex-COO Bloomberg India' },
    { img: investor12, name: 'Cristian Manea', designation: 'Co-Founder Aggero ' },
    { img: investor14, name: 'Clement Chia', designation: 'CEO Imaginary Ones' },
    { img: investor15, name: 'Aayush Gupta', designation: 'Director AnyLabs' },
  ];

  const logos = [logo3, logo1, logo4, logo5, logo2];

  return (
    <div className="investors-page">
      <div className="header">
        <h2 className="gradient-text">Backed by the Best</h2>
        <p>Empowered by the financial expertise and strategic insights of our backers.</p>
        <div className="underline-image"></div>
      </div>
      <div className="logos-grid">
        {logos.map((logo, index) => (
          <div key={index} className="logo-card">
            <img src={logo} alt={`logo${index + 1}`} className="logo-img" />
          </div>
        ))}
      </div>
      <div className="investors-grid">
        {investors.map((investor, index) => (
          <div key={index} className="investor-card">
            <div className="investor-hover">
              <img src={investor.img} alt={investor.name} className="investor-img" />
            </div>
            <h3>{investor.name}</h3>
            <p>{investor.designation}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Investors;
